export default [
  // CLIENT SECTION
  {
    title: "Accueil",
    route: "board-public",
    icon: "HomeIcon",
    action: "read",
    resource: "PublicBoard",
  },
  {
    header: "Décision Immo",
    icon: "AwardIcon",
    action: "read",
    children: [
      {
        title: "Accéder à l'outil",
        route: "decimmo-searcher",
        action: "read",
        resource: "DecimmoPages",
        icon: "SearchIcon",
      },
      {
        title: "Mes études",
        route: "decimmo-records",
        action: "read",
        resource: "DecimmoPages",
        icon: "FolderIcon",
      },
    ],
  },
  {
    header: "APIs",
    icon: "PackageIcon",
    action: "read",
    resource: "RealestatePages",
    children: [
      {
        title: "RealEstate",
        icon: "CodepenIcon",
        action: "read",
        resource: "RealestatePages",
        children: [
          {
            title: "Gestion",
            route: {
              name: "realestate-manage",
            },
            action: "read",
            resource: "APIManagementPages",
            icon: "KeyIcon",
          },
          {
            title: "API Documentation",
            route: {
              name: "realestate-redoc",
              query: { jsonName: "0.1.0_code_realestate_v2.json" },
            },
            action: "read",
            resource: "RealestatePages",
            icon: "FileTextIcon",
          },
          {
            title: "Présentation",
            href: "https://www.cadredevie.fr/marche-immo",
            action: "read",
            resource: "RealestatePages",
            icon: "NavigationIcon",
          },
        ],
      },
      {
        title: "Emplacement",
        icon: "CheckCircleIcon",
        action: "read",
        resource: "SituationPages",
        children: [
          {
            title: "Gestion",
            route: {
              name: "situation-manage",
            },
            action: "read",
            resource: "APIManagementPages",
            icon: "KeyIcon",
          },
          {
            title: "API Documentation",
            route: {
              name: "situation-redoc",
              query: { jsonName: "1.0.0_code_situation_v1.json" },
            },
            action: "read",
            resource: "SituationPages",
            icon: "FileTextIcon",
          },
          {
            title: "Présentation",
            href: "https://www.cadredevie.fr/emplacement",
            action: "read",
            resource: "SituationPages",
            icon: "NavigationIcon",
          },
        ],
      },
      {
        title: "Estimation",
        icon: "TrendingUpIcon",
        action: "read",
        resource: "EstimatePages",
        children: [
          {
            title: "Gestion",
            route: {
              name: "estimate-manage",
            },
            action: "read",
            resource: "APIManagementPages",
            icon: "KeyIcon",
          },
          {
            title: "API Documentation",
            route: {
              name: "estimate-redoc",
              query: { jsonName: "1.0.0_code_estimate_v1.json" },
            },
            action: "read",
            resource: "EstimatePages",
            icon: "FileTextIcon",
          },
          {
            title: "Présentation",
            href: "https://www.cadredevie.fr/estimation",
            action: "read",
            resource: "EstimatePages",
            icon: "NavigationIcon",
          },
        ],
      },
    ],
  },
  {
    header: "Exemples",
    icon: "AirplayIcon",
    action: "read",
    children: [
      {
        title: "Portail d'annonces",
        route: "realestate-searcher",
        action: "read",
        resource: "RealestatePages",
        icon: "BookOpenIcon",
      },
      {
        title: "Estimateur de bien",
        route: "estimate-demo",
        action: "read",
        resource: "EstimatePages",
        icon: "DollarSignIcon",
      },
    ],
  },
  // ADMIN SECTION
  {
    header: "Admin",
    icon: "KeyIcon",
    action: "read",
    resource: "adminLinks",
    children: [
      {
        title: "Datas",
        icon: "ActivityIcon",
        action: "read",
        resource: "AdsPages",
        children: [
          {
            title: "Ventes",
            route: {
              name: "ads-sales",
            },
            action: "read",
            resource: "AdsPages",
            icon: "KeyIcon",
          },
          {
            title: "Locations",
            route: {
              name: "ads-rentals",
            },
            action: "read",
            resource: "AdsPages",
            icon: "GridIcon",
          },
          {
            title: "Programmes",
            route: {
              name: "ads-programs",
            },
            action: "read",
            resource: "AdsPages",
            icon: "AwardIcon",
          },
        ],
      },
      {
        title: "Flux",
        route: "flow-list",
        icon: "WindIcon",
        action: "read",
        resource: "FlowPages",
      },
      {
        title: "Produits",
        route: "product-list",
        icon: "PackageIcon",
        action: "read",
        resource: "ProductPages",
      },
      {
        title: "Clients",
        route: "customer-list",
        icon: "StarIcon",
        action: "read",
        resource: "CustomerPages",
      },
      {
        title: "Utilisateurs",
        route: "user-list",
        icon: "UserPlusIcon",
        action: "read",
        resource: "UserPages",
      },
      {
        title: "Aide",
        href: "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/pages/faq",
        action: "read",
        resource: "RealestatePages",
        icon: "HelpCircleIcon",
      },
    ],
  },
];
